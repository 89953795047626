const mimeTypesMap = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  ico: 'image/vnd.microsoft.icon',
};

export function mimeType(extension: string) {
  return mimeTypesMap[extension.toLowerCase()] || 'application/octet-stream';
}
