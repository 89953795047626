import type { OrdersFixOrderFixDesignRouteRpc, OrdersFixOrderGetPreassignedDesignLinkRouteRpc } from '@repo/contract';
import { type ComputedRef, type Ref, ref } from 'vue';
import { fetchRpc } from '../../../shared/transport/fetch-rpc';
import { mimeType } from '../../../utils/mime-types';
import type { OrderForClient } from '~/pages/orders/types/orders';
import { delay } from '~/utils/delay';
import { uploadFileToPresignedUrl } from '~/utils/presigned-url';

function getImageDimensions(file: File): Promise<{ width: number, height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      URL.revokeObjectURL(objectUrl); // Clean up the object URL
      resolve({ width, height });
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl); // Clean up the object URL on error
      reject(new Error('Failed to load image'));
    };

    img.src = objectUrl;
  });
}

type DesignRulesType = OrderForClient['products'][number]['designRules'];
type MimeTypesType = string[];

export function useDesignUpload(
  props: {
    idx: Ref<number> | ComputedRef<number>
    productId: Ref<number> | ComputedRef<number>
    onSuccess: (order: OrderForClient) => void
    designRules: ComputedRef<DesignRulesType> | Ref<DesignRulesType>
    dpi: number
    mimeTypes: ComputedRef<MimeTypesType> | Ref<MimeTypesType>
    isReadonly: Ref<boolean> | ComputedRef<boolean>
  },
) {
  const showUploaded = ref(false);
  const uploadedDuration = 2000;

  const showError = ref(false);
  const errorDuration = 2000;

  const showUploading = ref(false);
  const uploadingPercentage = ref(0);
  const uploadedMb = ref('0.00');
  const fileMb = ref('0.00');

  const showFileError = ref(false);

  const svgDpi = 72;
  const svgMimeType = mimeType('svg');

  async function handleUpload(files: FileList) {
    try {
      showFileError.value = false;

      const file = files[0];

      if (!props.mimeTypes.value.includes(file.type)) {
        showFileError.value = true;
        return;
      }

      const { width, height } = await getImageDimensions(file);

      let fileRuleHeight = props.designRules.value.fileRules.height;

      if (props.designRules.value.fileRules.units === 'in' && height) {
        fileRuleHeight = props.designRules.value.fileRules.height * props.dpi;
      }

      let fileRuleWidth = props.designRules.value.fileRules.width;

      if (props.designRules.value.fileRules.units === 'in' && width) {
        fileRuleWidth = props.designRules.value.fileRules.width * props.dpi;
      }

      if (fileRuleWidth && +width !== +fileRuleWidth) {
        if (file.type === svgMimeType) {
          if (fileRuleWidth / svgDpi !== +width) {
            showFileError.value = true;
            return;
          }
        }
        else {
          showFileError.value = true;
          return;
        }
      }

      if (fileRuleHeight && +height !== +fileRuleHeight) {
        if (file.type === svgMimeType) {
          if (fileRuleHeight / svgDpi !== +height) {
            showFileError.value = true;
            return;
          }
        }
        else {
          showFileError.value = true;
          return;
        }
      }

      const { link } = await fetchRpc<typeof OrdersFixOrderGetPreassignedDesignLinkRouteRpc>(
        '/api/rpc/orders/fix-order/get-preassigned-design-link',
        {
          personalizationId: props.idx.value,
          orderProductId: props.productId.value,
          fileType: file.type as typeof OrdersFixOrderGetPreassignedDesignLinkRouteRpc.request.fileType,
        },
      );

      fileMb.value = (file.size / (1024 * 1024)).toFixed(2);

      showUploading.value = true;
      await uploadFileToPresignedUrl(link, file, (progress, mb) => {
        uploadingPercentage.value = Math.round(progress);

        uploadedMb.value = mb.toFixed(2);
      });
      showUploading.value = false;

      showUploaded.value = true;
      const [orderPromise] = await Promise.allSettled([
        fetchRpc<typeof OrdersFixOrderFixDesignRouteRpc>('/api/rpc/orders/fix-order/fix-design', {
          personalizationId: props.idx.value,
          orderProductId: props.productId.value,
          value: link.split('?')[0],
        }),
        delay(uploadedDuration),
      ]);
      showUploaded.value = false;

      if (orderPromise.status === 'fulfilled') {
        props.onSuccess(orderPromise.value.order);
      }
    }
    catch (e) {
      console.error(e);

      showError.value = true;

      await delay(errorDuration);

      showError.value = false;
    }
  }

  function dropped(e: DragEvent) {
    const { files } = e.dataTransfer!;

    console.log('files', files);

    handleUpload(files);

    hideDraggingOverlay();
  }

  const isDragging = ref(false);

  function showDraggingOverlay() {
    if (props.isReadonly.value) {
      return;
    }

    isDragging.value = true;
  }

  function hideDraggingOverlay() {
    isDragging.value = false;
  }

  return {
    showUploaded,
    uploadedDuration,
    showUploading,
    showError,
    uploadingPercentage,
    uploadedMb,
    fileMb,
    handleUpload,
    dropped,
    showFileError,
    isDragging,
    showDraggingOverlay,
    hideDraggingOverlay,
  };
}
